import React from 'react'

export default function Label({ title, children, className, error }) {
  const messages = {
    'pattern': 'Mauvais format',
    'required': 'Ce champ est requis',
    'minLength': 'Contenu trop court',
    'maxLength': 'Contenu trop long'
  }
  return (
    <label className={`relative block px-3 pt-2 pb-6 w-full lg:w-1/2 text-gray-700 overflow-hidden ${className} ${error && 'text-red-800'}`}>
      <div className="flex justify-between mb-1 text-sm font-medium pr-2">
        {title}
      </div>
      {children}
      {error &&
        <div className="absolute mt-1 leading-none font-light text-xs text-red opacity-50">
          {error.type ? messages[error.type] : `${error}`}
        </div>
      }
    </label>
  )
}

import React, { useState } from 'react'
import loadImage from 'blueimp-load-image/js'
import ReactCrop from 'react-image-crop'
import cropStyle from '!!raw-loader!react-image-crop/dist/ReactCrop.css'
import Button from './UI/Button'
import Title from './UI/Title'

const Uploader = ({ setPhoto, trigger }) => {
  const [imgRef, setImgRef] = useState(null)
  const [upload, setUpload] = useState(null)
  const [crop, setCrop] = useState({})

  const onSelectFile = e => {
    const input = e.target
    if (input.files && input.files.length > 0) {
      loadImage(
        input.files[0],
        img => {
          setUpload(img.toDataURL('image/jpeg', 0.8))
          input.value = ''
        },
        {
          orientation: true,
          canvas: true
        }
      )
    }
  }

  const onLoad = image => {
    setImgRef(image)
    setTimeout(() => {
      const width = image.width > image.height ? image.height : image.width
      const height = image.height > image.width ? image.width : image.height
      const x = width === image.width ? 0 : (image.width - width) / 2
      const y = height === image.height ? 0 : (image.height - height) / 2
      setCrop({ unit: 'px', aspect: 1, width, height, x, y })
    }, 100)
    return false
  }

  const renderCrop = async (image, crop) => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const lowerRatio = Math.max((crop.width * scaleX) / 1500, 1)
    canvas.width = (crop.width * scaleX) / lowerRatio
    canvas.height = (crop.height * scaleX) / lowerRatio
    const ctx = canvas.getContext('2d')
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      (crop.width * scaleX) / lowerRatio,
      (crop.height * scaleY) / lowerRatio
    )
    setUpload(null)
    setImgRef(null)
    setCrop({})
    setPhoto(canvas.toDataURL('image/jpeg', 0.7), canvas.width)
  }

  const cancel = () => {
    setUpload(null)
    setImgRef(null)
    setCrop({})
  }
  return (
    <div>
      <input
        accept="image/*"
        onChange={onSelectFile}
        ref={trigger}
        style={{ position: 'fixed', visibility: 'hidden' }}
        type="file" />
      {upload && (
        <div
          style={{
            position: 'fixed',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <div className="flex justify-center items-center flex-col h-full">
            <Title className="mb-12">Je recadre ma photo</Title>
            <style dangerouslySetInnerHTML={{ __html: cropStyle }} />
            <style dangerouslySetInnerHTML={{ __html: `body { overflow: hidden !important; }` }} />
            <div className="flex justify-center" style={{ width: (imgRef && imgRef.width > imgRef.height) ? '80vw' : '45vh', maxWidth: 900, position: 'relative' }}>
              <ReactCrop
                crop={crop}
                keepSelection
                onChange={c => setCrop(c)}
                onImageLoaded={onLoad}
                ruleOfThirds
                src={upload} />
            </div>
            <div className="absolute bottom-0 mb-4 flex" style={{ left: '50%', transform: 'translateX(-50%)' }}>
              <Button onClick={() => renderCrop(imgRef, crop)} style={{ margin: 10 }}>
                Valider
              </Button>
              <Button className="grey" onClick={cancel} style={{ margin: 10 }}>
                Annuler
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Uploader

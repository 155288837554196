import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Uploader from './Uploader'
import Button from './UI/Button'
import Label from './UI/Label'
import Title from './UI/Title'

let uploadRef

export default function Form({ contribution, update }) {
  const { getValues, setValue, register, trigger: validate, errors } = useForm({ mode: 'onChange' })
  const [photo, setPhoto] = useState((contribution.medias || {}).url)
  const [photoSize, setSize] = useState()
  const [thanks, setThanks] = useState()
  useEffect(() => {
    register({ name: 'medias' })
    validate()
    // setInterval(() => console.log(errors), 2000)
  }, [])
  const closed = contribution.project.status !== 'open'
  if (thanks) return (
    <>
      <Title>Merci de votre participation !</Title>
      <Button
        className="my-6 lg:my-12"
        onClick={() => window.location.reload()}>
          Modifier ma participation
      </Button>
    </>
  )
  if (closed) return <Title>Les participation sont actuellement fermées !</Title>
  return (
    <>
      <Label title="Votre photo" error={errors.medias}>
        {photo &&
          <img className="mb-4 w-32 lg:w-56 rounded" src={photo} />
        }
        {photoSize && photoSize < 850 &&
          <div className="leading-none font-light text-xs text-red opacity-50 mb-4">Attention, la photo est pixelisée, essayez d'en choisir une de meilleur qualité</div>
        }
        <Button variant="secondary" onClick={() => uploadRef && uploadRef.click()}>
          {photo ? 'Modifiez votre photo' : 'Téléchargez votre photo'}
        </Button>
      </Label>
      <Uploader
        setPhoto={(base64, size) => { setValue('medias', base64); validate(); setPhoto(base64); setSize(size) }}
        trigger={ref => (uploadRef = ref)} />
      <Label title="Votre petit mot à transmettre" error={errors.message}>
        <textarea className="form-input w-full" rows="4" defaultValue={contribution.message} name="message" type="text" ref={register({ required: true, minLength: 15, maxLength: 250 })} />
      </Label>
      <Label title="Vos prénom et nom" error={errors.name}>
        <input className="form-input w-full" type="text" defaultValue={contribution.name} name="name" ref={register({ required: true, minLength: 4, maxLength: 40 })} />
      </Label>
      <Button
        className="my-6 lg:my-12"
        disabled={Object.keys(errors).length !== 0}
        onClick={() => update(getValues()).then(() => setThanks(true)).catch(e => console.error(e))}>
          Confirmer ma participation
      </Button>
    </>
  )
}

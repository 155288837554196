import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { findContribution, updateContribution } from '../api'
import Footer from '../components/Footer'
import Form from '../components/Form'
import Header from '../components/Header'
import Loader from '../components/UI/Loader'
import NotFound from '../components/UI/NotFound'

const location = () => (typeof window !== `undefined`) ? window.location : { pathname: '' }
const match = (location().pathname.match(/contribution\/(\d+)_(.+)/) || [])
const contributionId = match[1]
const token = match[2]

export default function Contribution() {
  const [contribution, setContribution] = useState(null)
  useEffect(() => {
    findContribution(contributionId, token).then(e => setContribution(e))
  }, [])
  if (!contribution) return <Loader />
  if (contribution.error) return <NotFound />
  return ( 
    <div className="flex flex-col justify-center items-center w-full mx-auto" style={{ maxWidth: 900 }}>
      <Header project={contribution.project} />
      <div className="text-left text-lg lg:text-xl font-light my-6 max-w-2xl">
        <ReactMarkdown>{contribution.project.information}</ReactMarkdown>
      </div>
      <Form contribution={contribution} update={data => updateContribution(contributionId, token, data)} />
      <Footer />
    </div>
  )
}
